import {scanFile} from "../pdfscan";
import {compareCredentialWithOriginal} from "./OriginalCredential";
import {computeChecksumMd5} from "./computeChecksumMD5";

export async function retrieve(dropFile: any): Promise<any> {

    const retrieved = {qr: '', certId: '', qrFileHash: '', equalHash: false};
    let resultBody = {status: 'ok', message: 'Archivo subido con éxito', retrieved};
    try {
        const retrievedQR: string | null = await scanFile(dropFile);
        const blob = new Response(dropFile);
        const blobBff = await blob.blob();
        let file = await blobBff.arrayBuffer();
        const qrFileHash = await computeChecksumMd5(file);
        const credentialId = retrievedQR!.substring(retrievedQR!.lastIndexOf('/') + 1);
        resultBody = {
            ...resultBody,
            retrieved: {qr: retrievedQR!, certId: credentialId, qrFileHash: qrFileHash, equalHash: true}
        }
    } catch (e: any) {
        let msg = ''
        if (e?.name === "InvalidPDFException") msg = "El PDF no es válido";
        else if (e instanceof Event) msg = "La imagen no es válida";
        else msg = "Imposible acceder al archivo";
        resultBody = {
            status: 'fail', message: msg, retrieved: retrieved
        }
    }



    try {
        const hashEquals = await compareCredentialWithOriginal(resultBody.retrieved.certId, resultBody.retrieved.qrFileHash);
        resultBody = {...resultBody, retrieved: {...resultBody.retrieved, equalHash: hashEquals}}
    } catch (e: any) {
        resultBody = {
            status: 'fail',
            message: 'La credencial no existe o ha sido revocada.',
            retrieved: { ...resultBody.retrieved, equalHash: false }
        }
    }
    return resultBody;
}
