/* eslint-disable */
import ApiCaller from "../../lib/ApiCaller";
import DocumentsApi from "../../api/DocumentsApi";
import {base64} from "@scure/base";

class Jabbacerts {
    hash: string;
    src: any;

    constructor(src: any, hash?: string) {
        this.hash = hash ? hash : "";
        this.src = src;
    }

    call(): Promise<void | any> {
        const docApi = new DocumentsApi(new ApiCaller());
        return docApi.getCredentialPayload(this.hash).then((doc) => {
            this.src.hash = this.hash;
            this.src.badge.name = doc.credentialSubject.name;
            this.src.badge.description = "Documento legal";
            this.src.issuedOn = doc.issuanceDate;
            this.src.expires = doc.expirationDate
            this.src.recipientProfile.name = doc.credentialSubject.attributes[0].name;
            this.src.badge.issuer.email = doc.issuer.name;
            this.src.badge.issuer.image = null;
            this.src.badge.issuer.name = doc.issuer.name;
            this.src.file = base64.decode(doc.credentialSubject.evidenceDocument).buffer;
            this.src.filestr = doc.credentialSubject.evidenceDocument;
            return this.src;
        }).catch((err) => {
            this.src.badge.name = '';
            this.src.badge.description = '';
            this.src.badge.issuer.email = '';
            this.src.badge.issuer.image = '';
            this.src.badge.issuer.name = '';
            this.src.issuedOn = '';
            this.src.recipientProfile.name = '';
            this.src.STATUS = err.message;
            this.src.badge.issuer.email = 'UNKNOWN';
            return this.src;
        })
    }

    verifyRevoked(hash: string): Promise<boolean> {
        const docApi = new DocumentsApi(new ApiCaller());
        return docApi.getRevocationList(hash).then((revocationList) => {
            const assertions = revocationList.revokedAssertions;
            return !!(assertions && assertions.length);
        }).catch((err) => {
            console.error(err.message);
            throw err;
        })
    }
}

export default Jabbacerts;
