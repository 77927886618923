import { ReactNode } from 'react';

import { FooterCopyright } from './FooterCopyright';

type ICenteredFooterProps = {
  logo: ReactNode;
  children: ReactNode;
};

const CenteredFooter = (props: ICenteredFooterProps) => (
  <div className="text-center">

    <div className="text-sm pr-4 pt-4">
      <FooterCopyright logo={props.logo}/>
    </div>

  </div>
);

export { CenteredFooter };
