import className from 'classnames';

type IButtonProps = {
  xl?: boolean;
  icon?: string;
  children: string;
  onClick?: any;
  hideMobile?: boolean
};

const Button = (props: IButtonProps) => {
  const btnClass = className({
    flex: true,
    btn: true,
    'btn-xl': props.xl,
    'btn-base': !props.xl,
    'btn-primary': true,
    'cursor-pointer': true,
    'invisible': !!props.hideMobile,
    'sm:visible': true
  });

  return (
      props.icon ?
          <>
            <div className={btnClass} onClick={props.onClick}>
                <div className="flex pr-4"><img alt="" src={props.icon}/></div>
                <div className="flex"> {props.children}</div>
            </div>
          </> :
          <>
            <div className={btnClass} onClick={() => props.onClick}>
                {props.children}
            </div>
          </>
  );
};

export { Button };
