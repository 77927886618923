import * as SparkMD5 from 'spark-md5';

export function computeChecksumMd5(file: ArrayBuffer): Promise<string> {
    return new Promise((resolve, reject) => {
        try {
            const spark = new SparkMD5.ArrayBuffer();
            spark.append(file);
            const rawHash = spark.end(true);
            let base64 = window.btoa(rawHash)
            resolve(base64);
        } catch (e:any) {
            reject('error: invalid hash');
        }
    });
}
