import {ButtonStatus} from "../button/ButtonStatus";


type IStatusProps = {
    verificationStatus?: string;
};

const VerificationStatus = (props: IStatusProps) => {

    const getStatusLabel = (status: string) => {
        switch (status) {
            case 'success':
                return <ButtonStatus bold xl type="success">Verificado</ButtonStatus>
            case 'failure':
                return <ButtonStatus bold xl type="invalid">Invalido</ButtonStatus>;
            case 'error':
                return <ButtonStatus bold xl type="invalid">Imposible de Verificar. Problema con la conexión</ButtonStatus>
            default:
                return <></>
        }
    }

    return (
        <section className="pb-5">
            {
                getStatusLabel(props.verificationStatus ?? "")
            }
        </section>

    );
}

export { VerificationStatus };
