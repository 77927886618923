import ApiCaller from '../lib/ApiCaller';
import DocumentsApi from '../api/DocumentsApi';
import {base64} from "@scure/base";

export const downloadCertificateFile = (id: string): Promise<any> => {
    const documentApi = new DocumentsApi(new ApiCaller());

    return documentApi.getCredentialDocument(id).then((cert) => {
        const file = base64.decode(cert.credentialSubject.evidenceDocument);
        return file.buffer;
    }).catch((err) => {
        throw Error('Error retrieving file hash.')
    });
}
