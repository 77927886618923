/* eslint-disable */

const Settings = {
    getApiURL: (): string => {
        //const baseUrl = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'http://localhost';
        const baseUrl = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'https:/sign.docanda.com:8443';
        return `${baseUrl}/api`;
    },

    getLandingURL: (): string => {
        return 'https://docanda.com';
    }
}

export default Settings;
