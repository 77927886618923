/* eslint-disable */
import ApiCaller from '../lib/ApiCaller';

class DocumentsApi {

    apiCaller: ApiCaller;

    constructor(apiCaller: ApiCaller) {
        this.apiCaller = apiCaller;
    }

    getCredentialPayload(id: string): Promise<void | any> {
        const url = '/v1/certification/vc/' + id ;
        return this.apiCaller.call(url, 'GET').then((res: any) => {
            if (res.err) throw Error("INVALID_CREDENTIAL");
            return res
        }).catch((err) => {
            throw err;
        })
    }

    getCredentialDocument(id: string): Promise<void | any> {
        const url = `/v1/certification/vc/${id}`;
        return this.apiCaller.call(url, 'GET').then((file) => {
            if (file.err) throw Error("INVALID_CREDENTIAL");
            return file;
        });
    }

    getRevocationList(id: string): Promise<void | any> {
        const url = '/public/crl/credential/' + id;
        return this.apiCaller.call(url, 'GET').then((res) => {
            return res
        })
    }
}

export default DocumentsApi;
