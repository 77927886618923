import { Section } from '../layout/Section';
import {DropArea} from "../droparea/dropArea";
import {Button} from "../button/Button";

type IUploadProps = {
    _onResponse: Function;
    _onReset: Function;
};

const PdfUpload = (props:IUploadProps) => (
  <Section yPadding={'px-10'}>
    <DropArea _onResponse={props._onResponse}
              _onReset={props._onReset}
              title="Arrastra aquí tu documento o credencial"
              subtitle="- o - "
              button={<Button hideMobile={false}>Localiza tu documento en archivos</Button>}
    />
  </Section>
);

export { PdfUpload };
