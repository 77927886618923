import className from 'classnames';

type IButtonProps = {
  xl?: boolean;
  bold?: boolean;
  type:string;
  children: string;
};

const ButtonStatus = (props: IButtonProps) => {
  const btnClass = className({
    btn: true,
    'btn-xl': props.xl,
    'btn-base': !props.xl,
    'btn-success': props.type === 'success',
    'btn-invalid': props.type === 'invalid',
    'btn-verifying': props.type === 'verifying',
    'font-semibold': props.bold,
    'font-normal': !props.bold,
    'shadow': true
  });

  return (
      <div className="">
        <div className={btnClass}>

          <div className="grid place-items-center">
            <div className="flex flex-row space-x-4">
              <div>{props.children}</div>
              <div className="pt-1 px-1">
                {props.type === 'success' && <img alt="" src="/assets/images/verified.svg"/>}
                {props.type === 'invalid' && <img alt="" src="/assets/images/warning.svg"/>}
                {props.type === 'verifying' && <img alt="" src="/assets/images/loader.svg"/>}
              </div>
            </div>
          </div>

        </div>
      </div>
  );
};

export { ButtonStatus };
