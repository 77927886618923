import moment from "moment";

export const DEFAULT_TIMEZONE_FORMAT = 'Do MMMM YYYY, HH:mm (UTC)'

export const verifyDateActive = (date?: string): boolean => {
    if (!date) return true;

    return moment(date).isAfter(moment.now());
}

export const formatDateTimeUTC = (date: string, format = DEFAULT_TIMEZONE_FORMAT) =>
    date ? moment(date).utc().format(format) : date;
