import React from "react";
import "./spinner.css";

export default function LoadingSpinner() {
    return (
        <div className="">
            <div className="loading-spinner"/>
        </div>
    );
}
