
import { Header } from '../cta/Header';
import { Section } from '../layout/Section';
import {ReactNode} from "react";

export type ICTABannerProps = {
    title: string;
    subtitle: string;
    button?: ReactNode;
};

const Banner = (props: ICTABannerProps) => (
  <Section >
    <Header
      title={props.title}
      subtitle={props.subtitle}
      button={props.button}

    />
  </Section>
);

export { Banner };
