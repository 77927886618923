import { Banner } from './Banner';
import { Footer } from './footer/Footer';
import {useEffect, useState} from "react";
import cert from '../components/blockcerts/testnet-valid-2.0.json';
import Jabbacerts from "../components/blockcerts/Jabbacerts";
import {VerifierMain} from "../verifier/main";
import {Background} from "../background/Background";
import {Button} from "../button/Button";
import buttonIcon from '../images/document.svg';
import {useHistory} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {HowItWorks} from "./HowItWorks";
import download from "../images/step.svg";
import {base64} from "@scure/base";
import {saveAs} from "file-saver";

const defaultImage = "/assets/images/logo-qr.png";

type IVerifierProps = {
    hash: string;
};

const Verifier = (props:IVerifierProps) => {
    const [parsedCert, setParsedCert] = useState<any>();
    const history = useHistory();
    const [docBuffer, setDocBuffer] = useState<any>();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 640px)'
    })

    useEffect(() => {
        const navocerts = new Jabbacerts(cert, props.hash);
        navocerts.call().then((parsed) => {
            setParsedCert(parsed);
            setDocBuffer(parsed.filestr);
        });
    }, [props.hash]);

    const navigateHome = () => {
        history.push('/');
    }

    const _onDownload = async  () => {
        const buffer = base64.decode(docBuffer).buffer;
        saveAs(new Blob([buffer]),'legal_document_agreemy.pdf');
    }
    return (
        <Background>
            <div className="flex flex-col h-screen w-screen overflow-x-hidden overflow-y-hidden">
                {parsedCert ?
                    <div className="flex-none">
                        <Banner title={parsedCert.badge?.name} subtitle={parsedCert.badge?.description}
                                button={ <div className="flex gap-2">
                                    <Button children={'Descarga'} icon={download} onClick={_onDownload} hideMobile={!isDesktopOrLaptop}/>
                                    <Button children={'Verifica otro pdf'} icon={buttonIcon} onClick={navigateHome} hideMobile={!isDesktopOrLaptop}/>

                                </div>}/>
                        <VerifierMain image={defaultImage} src={parsedCert} finish={parsedCert.badge?.name!=''}/>
                    </div> :
                    <div className="flex-none">
                        <p></p>
                    </div>
                        }
                { parsedCert &&
                <div className="flex flex-col grow items-center justify-end pb-8 sm:pb-40">
                    {/*<div>*/}
                    {/*    <HowItWorks/>*/}
                    {/*</div>*/}
                    <div>
                        <Footer />
                    </div>
                </div>
                }
            </div>
        </Background>
    );
}

export { Verifier };
