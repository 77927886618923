
const LogoDark = () => {
  return (
    <span className={`text-gray-900 inline-flex items-center`}>
        <svg width="100" height="23" viewBox="0 0 830 237" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M49.3779 94.8219H16.4616C-14.1323 50.7476 16.9895 7 16.9941 7L49.3545 7C49.3545 7 6.46153 52.2474 49.3779 94.8219Z" fill="#855BFF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M69.113 80.0203H47.4022C27.2233 50.8025 47.7504 21.8013 47.7535 21.8013L69.0976 21.8013C69.0976 21.8013 40.8064 51.7967 69.113 80.0203Z" fill="#855BFF"/>
<path d="M92.7955 55.7903C92.7955 47.7262 94.393 40.5741 97.588 34.3341C100.88 28.094 105.285 23.294 110.804 19.934C116.419 16.5739 122.664 14.8939 129.538 14.8939C135.541 14.8939 140.769 16.0939 145.223 18.4939C149.773 20.894 153.404 23.918 156.115 27.566V16.1899H176.592V95.9666H156.115V84.3025C153.501 88.0466 149.87 91.1666 145.223 93.6626C140.672 96.0626 135.396 97.2626 129.393 97.2626C122.616 97.2626 116.419 95.5346 110.804 92.0786C105.285 88.6226 100.88 83.7745 97.588 77.5345C94.393 71.1984 92.7955 63.9504 92.7955 55.7903ZM156.115 56.0783C156.115 51.1822 155.147 47.0062 153.21 43.5502C151.274 39.9981 148.66 37.3101 145.368 35.4861C142.076 33.5661 138.542 32.6061 134.766 32.6061C130.99 32.6061 127.505 33.5181 124.31 35.3421C121.115 37.1661 118.501 39.8541 116.468 43.4062C114.531 46.8622 113.563 50.9902 113.563 55.7903C113.563 60.5903 114.531 64.8144 116.468 68.4624C118.501 72.0144 121.115 74.7504 124.31 76.6705C127.602 78.5905 131.087 79.5505 134.766 79.5505C138.542 79.5505 142.076 78.6385 145.368 76.8145C148.66 74.8944 151.274 72.2064 153.21 68.7504C155.147 65.1984 156.115 60.9743 156.115 56.0783Z" fill="#303030"/>
<path d="M238.13 14.8939C244.133 14.8939 249.409 16.0939 253.96 18.4939C258.51 20.798 262.093 23.822 264.707 27.566V16.1899H285.184V96.5426C285.184 103.935 283.683 110.511 280.682 116.271C277.68 122.127 273.178 126.735 267.176 130.095C261.173 133.551 253.911 135.279 245.391 135.279C233.967 135.279 224.575 132.639 217.217 127.359C209.956 122.079 205.841 114.879 204.873 105.759H225.06C226.125 109.407 228.4 112.287 231.885 114.399C235.468 116.607 239.776 117.711 244.81 117.711C250.716 117.711 255.509 115.935 259.188 112.383C262.867 108.927 264.707 103.647 264.707 96.5426V84.1585C262.093 87.9026 258.462 91.0226 253.815 93.5186C249.264 96.0146 244.036 97.2626 238.13 97.2626C231.353 97.2626 225.156 95.5346 219.541 92.0786C213.925 88.6226 209.472 83.7745 206.18 77.5345C202.985 71.1984 201.387 63.9504 201.387 55.7903C201.387 47.7262 202.985 40.5741 206.18 34.3341C209.472 28.094 213.877 23.294 219.396 19.934C225.011 16.5739 231.256 14.8939 238.13 14.8939ZM264.707 56.0783C264.707 51.1822 263.739 47.0062 261.802 43.5502C259.866 39.9981 257.252 37.3101 253.96 35.4861C250.668 33.5661 247.134 32.6061 243.358 32.6061C239.582 32.6061 236.097 33.5181 232.902 35.3421C229.707 37.1661 227.093 39.8541 225.06 43.4062C223.123 46.8622 222.155 50.9902 222.155 55.7903C222.155 60.5903 223.123 64.8144 225.06 68.4624C227.093 72.0144 229.707 74.7504 232.902 76.6705C236.194 78.5905 239.679 79.5505 243.358 79.5505C247.134 79.5505 250.668 78.6385 253.96 76.8145C257.252 74.8944 259.866 72.2064 261.802 68.7504C263.739 65.1984 264.707 60.9743 264.707 56.0783Z" fill="#303030"/>
<path d="M335.539 28.574C338.153 24.35 341.542 21.038 345.705 18.638C349.965 16.2379 354.806 15.0379 360.228 15.0379V36.2061H354.855C348.465 36.2061 343.624 37.6941 340.332 40.6701C337.137 43.6462 335.539 48.8302 335.539 56.2223V95.9666H315.207V16.1899H335.539V28.574Z" fill="#303030"/>
<path d="M458.881 54.3503C458.881 57.2303 458.687 59.8223 458.3 62.1263H399.483C399.967 67.8864 402 72.3984 405.582 75.6625C409.165 78.9265 413.57 80.5585 418.798 80.5585C426.35 80.5585 431.723 77.3425 434.918 70.9104H456.848C454.524 78.5905 450.07 84.9265 443.487 89.9186C436.903 94.8146 428.819 97.2626 419.234 97.2626C411.488 97.2626 404.517 95.5826 398.321 92.2226C392.221 88.7666 387.429 83.9185 383.943 77.6785C380.555 71.4384 378.86 64.2384 378.86 56.0783C378.86 47.8222 380.555 40.5741 383.943 34.3341C387.332 28.094 392.076 23.294 398.176 19.934C404.275 16.5739 411.295 14.8939 419.234 14.8939C426.882 14.8939 433.708 16.5259 439.711 19.79C445.81 23.054 450.506 27.71 453.798 33.7581C457.187 39.7101 458.881 46.5742 458.881 54.3503ZM437.823 48.5902C437.726 43.4062 435.838 39.2781 432.159 36.2061C428.48 33.0381 423.978 31.4541 418.653 31.4541C413.618 31.4541 409.358 32.9901 405.873 36.0621C402.484 39.0381 400.402 43.2142 399.628 48.5902H437.823Z" fill="#303030"/>
<path d="M558.68 54.3503C558.68 57.2303 558.486 59.8223 558.099 62.1263H499.281C499.766 67.8864 501.799 72.3984 505.381 75.6625C508.963 78.9265 513.369 80.5585 518.597 80.5585C526.149 80.5585 531.522 77.3425 534.717 70.9104H556.646C554.323 78.5905 549.869 84.9265 543.285 89.9186C536.702 94.8146 528.617 97.2626 519.032 97.2626C511.287 97.2626 504.316 95.5826 498.12 92.2226C492.02 88.7666 487.228 83.9185 483.742 77.6785C480.353 71.4384 478.659 64.2384 478.659 56.0783C478.659 47.8222 480.353 40.5741 483.742 34.3341C487.131 28.094 491.875 23.294 497.974 19.934C504.074 16.5739 511.093 14.8939 519.032 14.8939C526.681 14.8939 533.507 16.5259 539.51 19.79C545.609 23.054 550.305 27.71 553.597 33.7581C556.985 39.7101 558.68 46.5742 558.68 54.3503ZM537.622 48.5902C537.525 43.4062 535.637 39.2781 531.958 36.2061C528.279 33.0381 523.777 31.4541 518.452 31.4541C513.417 31.4541 509.157 32.9901 505.671 36.0621C502.283 39.0381 500.201 43.2142 499.427 48.5902H537.622Z" fill="#303030"/>
<path d="M683.603 15.0379C693.478 15.0379 701.417 18.0619 707.42 24.11C713.52 30.0621 716.57 38.4141 716.57 49.1662V95.9666H696.238V51.9022C696.238 45.6622 694.64 40.9101 691.445 37.6461C688.25 34.2861 683.893 32.6061 678.375 32.6061C672.856 32.6061 668.451 34.2861 665.159 37.6461C661.964 40.9101 660.366 45.6622 660.366 51.9022V95.9666H640.034V51.9022C640.034 45.6622 638.437 40.9101 635.242 37.6461C632.047 34.2861 627.69 32.6061 622.171 32.6061C616.556 32.6061 612.102 34.2861 608.81 37.6461C605.615 40.9101 604.018 45.6622 604.018 51.9022V95.9666H583.686V16.1899H604.018V25.838C606.632 22.478 609.972 19.838 614.039 17.9179C618.202 15.9979 622.752 15.0379 627.69 15.0379C633.983 15.0379 639.599 16.3819 644.537 19.07C649.474 21.662 653.299 25.406 656.01 30.3021C658.624 25.694 662.4 21.998 667.337 19.214C672.372 16.4299 677.794 15.0379 683.603 15.0379Z" fill="#303030"/>
<path d="M823 16.1899L773.187 133.695H751.548L768.975 93.9506L736.735 16.1899H759.535L780.303 71.9184L801.361 16.1899H823Z" fill="#303030"/>
<path d="M92.7955 191.987C92.7955 188.162 93.5782 184.775 95.1436 181.825C96.755 178.875 98.919 176.594 101.635 174.981C104.398 173.322 107.46 172.492 110.821 172.492C113.307 172.492 115.747 173.045 118.141 174.152C120.581 175.212 122.515 176.64 123.942 178.438V160.049H131.884V211.205H123.942V205.468C122.653 207.311 120.857 208.832 118.555 210.03C116.299 211.228 113.698 211.828 110.751 211.828C107.437 211.828 104.398 210.998 101.635 209.339C98.919 207.634 96.755 205.283 95.1436 202.288C93.5782 199.246 92.7955 195.812 92.7955 191.987ZM123.942 192.125C123.942 189.498 123.39 187.217 122.285 185.282C121.226 183.346 119.822 181.871 118.072 180.857C116.322 179.843 114.435 179.336 112.409 179.336C110.383 179.336 108.495 179.843 106.746 180.857C104.996 181.825 103.569 183.277 102.464 185.212C101.405 187.102 100.876 189.36 100.876 191.987C100.876 194.614 101.405 196.918 102.464 198.9C103.569 200.882 104.996 202.403 106.746 203.463C108.542 204.477 110.429 204.984 112.409 204.984C114.435 204.984 116.322 204.477 118.072 203.463C119.822 202.449 121.226 200.974 122.285 199.038C123.39 197.057 123.942 194.752 123.942 192.125Z" fill="#303030"/>
<path d="M162.805 168.068C161.378 168.068 160.181 167.584 159.214 166.616C158.247 165.649 157.764 164.45 157.764 163.022C157.764 161.593 158.247 160.395 159.214 159.427C160.181 158.459 161.378 157.975 162.805 157.975C164.187 157.975 165.361 158.459 166.327 159.427C167.294 160.395 167.778 161.593 167.778 163.022C167.778 164.45 167.294 165.649 166.327 166.616C165.361 167.584 164.187 168.068 162.805 168.068ZM166.673 173.115V211.205H158.8V173.115H166.673Z" fill="#303030"/>
<path d="M208.916 172.492C211.862 172.492 214.464 173.092 216.72 174.29C219.022 175.442 220.817 176.894 222.106 178.645V173.115H230.048V211.828C230.048 215.33 229.312 218.441 227.838 221.16C226.365 223.925 224.224 226.091 221.416 227.658C218.653 229.225 215.338 230.009 211.471 230.009C206.314 230.009 202.032 228.787 198.625 226.345C195.218 223.948 193.285 220.676 192.824 216.528H200.628C201.227 218.51 202.493 220.1 204.427 221.298C206.406 222.543 208.754 223.165 211.471 223.165C214.648 223.165 217.203 222.197 219.137 220.261C221.116 218.326 222.106 215.514 222.106 211.828V205.468C220.771 207.265 218.952 208.786 216.65 210.03C214.394 211.228 211.816 211.828 208.916 211.828C205.601 211.828 202.562 210.998 199.8 209.339C197.083 207.634 194.919 205.283 193.308 202.288C191.742 199.246 190.96 195.812 190.96 191.987C190.96 188.162 191.742 184.775 193.308 181.825C194.919 178.875 197.083 176.594 199.8 174.981C202.562 173.322 205.601 172.492 208.916 172.492ZM222.106 192.125C222.106 189.498 221.554 187.217 220.449 185.282C219.39 183.346 217.986 181.871 216.236 180.857C214.487 179.843 212.599 179.336 210.573 179.336C208.547 179.336 206.66 179.843 204.91 180.857C203.16 181.825 201.733 183.277 200.628 185.212C199.569 187.102 199.04 189.36 199.04 191.987C199.04 194.614 199.569 196.918 200.628 198.9C201.733 200.882 203.16 202.403 204.91 203.463C206.706 204.477 208.593 204.984 210.573 204.984C212.599 204.984 214.487 204.477 216.236 203.463C217.986 202.449 219.39 200.974 220.449 199.038C221.554 197.057 222.106 194.752 222.106 192.125Z" fill="#303030"/>
<path d="M260.969 168.068C259.542 168.068 258.345 167.584 257.378 166.616C256.411 165.649 255.928 164.45 255.928 163.022C255.928 161.593 256.411 160.395 257.378 159.427C258.345 158.459 259.542 157.975 260.969 157.975C262.351 157.975 263.525 158.459 264.492 159.427C265.458 160.395 265.942 161.593 265.942 163.022C265.942 164.45 265.458 165.649 264.492 166.616C263.525 167.584 262.351 168.068 260.969 168.068ZM264.837 173.115V211.205H256.964V173.115H264.837Z" fill="#303030"/>
<path d="M300.933 179.544V200.628C300.933 202.057 301.256 203.094 301.9 203.739C302.591 204.338 303.742 204.638 305.353 204.638H310.187V211.205H303.972C300.427 211.205 297.71 210.376 295.823 208.717C293.935 207.058 292.991 204.361 292.991 200.628V179.544H288.502V173.115H292.991V163.644H300.933V173.115H310.187V179.544H300.933Z" fill="#303030"/>
<path d="M331.394 191.987C331.394 188.162 332.177 184.775 333.742 181.825C335.354 178.875 337.518 176.594 340.234 174.981C342.996 173.322 346.035 172.492 349.35 172.492C352.343 172.492 354.944 173.092 357.154 174.29C359.41 175.442 361.206 176.894 362.541 178.645V173.115H370.483V211.205H362.541V205.537C361.206 207.334 359.387 208.832 357.085 210.03C354.783 211.228 352.159 211.828 349.212 211.828C345.943 211.828 342.95 210.998 340.234 209.339C337.518 207.634 335.354 205.283 333.742 202.288C332.177 199.246 331.394 195.812 331.394 191.987ZM362.541 192.125C362.541 189.498 361.988 187.217 360.883 185.282C359.824 183.346 358.42 181.871 356.671 180.857C354.921 179.843 353.033 179.336 351.008 179.336C348.982 179.336 347.094 179.843 345.344 180.857C343.595 181.825 342.168 183.277 341.063 185.212C340.004 187.102 339.474 189.36 339.474 191.987C339.474 194.614 340.004 196.918 341.063 198.9C342.168 200.882 343.595 202.403 345.344 203.463C347.14 204.477 349.028 204.984 351.008 204.984C353.033 204.984 354.921 204.477 356.671 203.463C358.42 202.449 359.824 200.974 360.883 199.038C361.988 197.057 362.541 194.752 362.541 192.125Z" fill="#303030"/>
<path d="M405.271 160.049V211.205H397.398V160.049H405.271Z" fill="#303030"/>
<path d="M474.57 160.049V211.205H466.697V160.049H474.57Z" fill="#303030"/>
<path d="M498.857 191.987C498.857 188.162 499.64 184.775 501.205 181.825C502.816 178.875 504.98 176.594 507.697 174.981C510.459 173.322 513.498 172.492 516.813 172.492C519.806 172.492 522.407 173.092 524.617 174.29C526.873 175.442 528.668 176.894 530.004 178.645V173.115H537.946V211.205H530.004V205.537C528.668 207.334 526.85 208.832 524.548 210.03C522.246 211.228 519.621 211.828 516.675 211.828C513.406 211.828 510.413 210.998 507.697 209.339C504.98 207.634 502.816 205.283 501.205 202.288C499.64 199.246 498.857 195.812 498.857 191.987ZM530.004 192.125C530.004 189.498 529.451 187.217 528.346 185.282C527.287 183.346 525.883 181.871 524.133 180.857C522.384 179.843 520.496 179.336 518.47 179.336C516.445 179.336 514.557 179.843 512.807 180.857C511.058 181.825 509.63 183.277 508.526 185.212C507.467 187.102 506.937 189.36 506.937 191.987C506.937 194.614 507.467 196.918 508.526 198.9C509.63 200.882 511.058 202.403 512.807 203.463C514.603 204.477 516.491 204.984 518.47 204.984C520.496 204.984 522.384 204.477 524.133 203.463C525.883 202.449 527.287 200.974 528.346 199.038C529.451 197.057 530.004 194.752 530.004 192.125Z" fill="#303030"/>
<path d="M615.966 173.115L604.157 211.205H595.87L588.204 183.069L580.538 211.205H572.251L560.372 173.115H568.383L576.325 203.739L584.406 173.115H592.624L600.359 203.601L608.232 173.115H615.966Z" fill="#303030"/>
<path d="M672.679 173.115L649.337 229.11H641.187L648.922 210.583L633.936 173.115H642.707L653.411 202.149L664.53 173.115H672.679Z" fill="#303030"/>
<path d="M730.071 191.227C730.071 192.655 729.979 193.946 729.795 195.098H700.72C700.951 198.14 702.079 200.582 704.104 202.426C706.13 204.269 708.616 205.191 711.563 205.191C715.799 205.191 718.791 203.417 720.541 199.868H729.035C727.884 203.371 725.79 206.251 722.751 208.509C719.758 210.721 716.029 211.828 711.563 211.828C707.926 211.828 704.657 211.021 701.756 209.408C698.902 207.749 696.646 205.445 694.988 202.495C693.377 199.499 692.571 196.043 692.571 192.125C692.571 188.208 693.354 184.775 694.919 181.825C696.531 178.829 698.764 176.525 701.618 174.912C704.519 173.299 707.834 172.492 711.563 172.492C715.154 172.492 718.354 173.276 721.162 174.843C723.971 176.41 726.158 178.622 727.723 181.479C729.289 184.291 730.071 187.54 730.071 191.227ZM721.853 188.738C721.807 185.835 720.771 183.507 718.745 181.756C716.72 180.005 714.21 179.129 711.218 179.129C708.501 179.129 706.176 180.005 704.242 181.756C702.309 183.461 701.158 185.788 700.789 188.738H721.853Z" fill="#303030"/>
<path d="M762.267 178.645C763.418 176.709 764.937 175.212 766.825 174.152C768.759 173.045 771.038 172.492 773.662 172.492V180.65H771.659C768.574 180.65 766.226 181.433 764.615 183C763.05 184.567 762.267 187.286 762.267 191.158V211.205H754.394V173.115H762.267V178.645Z" fill="#303030"/>
<path d="M423.637 191.242C421.283 184.2 425.083 176.584 432.125 174.23V174.23C439.167 171.877 446.783 175.677 449.136 182.719V182.719C451.49 189.76 447.689 197.377 440.648 199.73V199.73C433.606 202.084 425.99 198.283 423.637 191.242V191.242Z" fill="#855BFF"/>
</svg>

    </span>
  );
};

export { LogoDark };
