import { Banner } from './Banner';
import { Footer } from './footer/Footer';
import {PdfUpload} from "./PdfUpload";
import {useEffect, useState} from "react";
import {StepperStatus} from "../verifier/StepperStatus";
import Jabbacerts from "../components/blockcerts/Jabbacerts";
import cert from "../components/blockcerts/testnet-valid-2.0.json";
import {VerifierMain} from "../verifier/main";
import {Button} from "../button/Button";
import buttonIcon from "../images/document.svg";
import download from "../images/download_a.svg";
import {useMediaQuery} from "react-responsive";
import {ICTABannerProps} from "./Banner";
import {base64} from "@scure/base";
import { saveAs } from 'file-saver';

const defaultImage = "/assets/images/logo-qr.png";

const Base = () => {

    const [parsedCert, setParsedCert] = useState<any>();
    const [docBuffer, setDocBuffer] = useState<any>();
    const [documentProcessed, setDocumentProcessed] = useState<boolean>(false);
    const [hashNotEqual, setHashNotEqual] = useState<boolean>(true);

    const getDefaultBannerProps = (): ICTABannerProps  => {
        return {
            title: "Verificar documento legal",
            subtitle: "Sube una credencial o un PDF y comprueba su autenticidad",
            button: false
        }
    }

    const [bannerProps, setBannerProps] = useState<ICTABannerProps>(getDefaultBannerProps())

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 640px)'
    })

    useEffect(() => {
        if (parsedCert && !hashNotEqual) {
            setBannerProps({
                title: parsedCert.badge?.name,
                subtitle: parsedCert.badge?.description,
                button: getHeaderButton()
            });
        }
    }, [parsedCert, hashNotEqual, docBuffer])

    const _onResponse = async (resp: any) => {
        setDocumentProcessed(true);
        setHashNotEqual(!resp.retrieved.equalHash);
        const jabbacerts = new Jabbacerts(cert, resp.retrieved.certId);
        jabbacerts.call().then((parsed) => {
            setParsedCert(parsed);
            setDocBuffer(parsed.filestr);
        });
    }

    const _onReset = () => {
        setHashNotEqual(false);
        setParsedCert(false);
        setDocumentProcessed(false);
        setBannerProps(getDefaultBannerProps);
    }

    const _onDownload = async  () => {
        const buffer = base64.decode(docBuffer).buffer;
        saveAs(new Blob([buffer]),'legal_document_agreemy.pdf');
    }

    const getHeaderButton = () => {
        return <div className="flex gap-2">
            { docBuffer && <Button children={'Descarga'} icon={download} onClick={_onDownload} hideMobile={!isDesktopOrLaptop}/> }
            <Button children={'Sube otro documento'} icon={buttonIcon} onClick={_onReset} hideMobile={!isDesktopOrLaptop}/>
        </div>
    }

    const [finished, setFinished] = useState(false);
    return (
        <>
            <div className="flex flex-col h-screen w-screen overflow-x-hidden overflow-y-hidden border-2">
                <div className="flex-1">
                    <Banner title={bannerProps.title} subtitle={bannerProps.subtitle} button={bannerProps.button}/>
                </div>

                { documentProcessed && parsedCert && !hashNotEqual && (
                    <div className="flex-1 pb-10">
                        <VerifierMain src={parsedCert} image={defaultImage} hideSteps={true} finish={finished}/>
                    </div>
                )}

                { (!documentProcessed || !parsedCert || hashNotEqual) && (
                    <div className="flex-1 pb-1">
                        <PdfUpload _onResponse={_onResponse} _onReset={_onReset} />
                    </div>
                )}

                { parsedCert &&
                <>
                    <div className="flex-1 px-10">
                        <StepperStatus src={parsedCert} exceptionHashNotUnique={hashNotEqual} onFinish={()=>{setFinished(true)}}/>
                    </div>
                </>
                }

                <div className="flex flex-col grow items-center justify-end sm:pb-36">
                    <Footer />
                </div>
            </div>
        </>
    );
}

export { Base };
