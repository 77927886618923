import { Loader } from 'lucide-react';
import React, {lazy, Suspense} from "react";

const PDFViewer = lazy(() => import('./pdf-viewer'));



export const LazyPDFViewer= (blob:any) => {
    return <Suspense fallback={<div className="dark:bg-background flex h-[80vh] max-h-[60rem] flex-col items-center justify-center bg-white/50">
        <Loader className="text-documenso h-12 w-12 animate-spin" />
        <p className="text-muted-foreground mt-4">Cargando Documento...</p>
    </div>}>
        <PDFViewer documentData={{type:'pdf', data:blob}} />
    </Suspense>
};

