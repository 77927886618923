import {ReactNode} from "react";
import Settings from "../lib/settings";

type ICenteredFooterProps = {
    logo: ReactNode;
};

const FooterCopyright = (props: ICenteredFooterProps) => {
    const isEmbedded = window.location !== window.parent.location;

    return (<div className="footer-copyright">
        { isEmbedded ?
            <p className="text-primary-500 sm:text-primary-100">
                Desarrollado por
                <span className="absolute pl-2">{props.logo}</span>
            </p> :
            <a className="text-primary-500 sm:text-primary-100" href={Settings.getLandingURL()}>
                Desarrollado por
                <span className="absolute pl-2">{props.logo}</span>
            </a>
        }
        <style>
            {`
        .footer-copyright :global(a) {
          @apply text-primary-100;
        }

        .footer-copyright :global(a:hover) {
          @apply underline;
        }
      `}
        </style>
    </div>)
};

export { FooterCopyright };
